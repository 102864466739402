import BaseBean from "@/utils/BaseBean";

export interface IUserCardDataObj {
    utilInst:UserCardUtil
    refMap:Map<string,any>
    formRef:any
    disabled:boolean
    compParams:any
    personData: Array<any>
    roleData:Array<any>
    form: any
    rules: TFormRule
    otherParams: any
}
export default class UserCardUtil extends BaseBean{
    public dataObj:IUserCardDataObj

    constructor(proxy:any,dataObj:IUserCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    public async buildPersonData():Promise<any>{
        this.dataObj.personData= await this.utils.Api.getPersons();
    }
    public async buildRoleData(options:any):Promise<any>{
        this.dataObj.roleData= await this.utils.Api.getUserRoles(options);
    }
    public async buildZoneTreeData(options:any):Promise<any>{
        this.dataObj.otherParams.zoneTreeData=await this.utils.OrderProviderApi.getZoneTreeData({params:{type:0}});
    }
    //停用启用user
    public changeStatus(params:any):void{
        this.utils.Tools.configBox({
            message:'确定要执行该操作吗?',
            sureFn:async ()=> {
                let url = this.dataObj.compParams.modelPath + "/custom";
                let res = await this.utils.Api.postRequest({ url: url, params: params});
                if(res.result){
                    this.utils.Tools.success({ message: res.msg });
                    if (this.proxy.engine.engineParams.ownerComp && this.proxy.engine.engineParams.ownerComp.queryHandler) this.proxy.engine.engineParams.ownerComp.queryHandler();
                    await this.proxy.engine.engineUtil.doAddOrLoad(this.proxy.engine.id);
                }
            }
        });
    }
    //重置密码
    public resetPwd():void{
        this.utils.Tools.configBox({
            message:'确定要执行该操作吗?',
            sureFn:async ()=> {
                let url = this.dataObj.compParams.modelPath + "/custom";
                let params={id:this.dataObj.form.id,operateType:'resetPwd'}
                let res = await this.utils.Api.postRequest({ url: url, params: params});
                this.utils.Tools.success({ message: res.msg });
            }
        });
    }
}